import {
  GET_ACCOUNT_PROFILE_START,
  GET_ACCOUNT_PROFILE_SUCCESS,
  GET_ACCOUNT_PROFILE_FAILED,
  UPDATE_ACCOUNT_PROFILE_START,
  UPDATE_ACCOUNT_PROFILE_SUCCESS,
  UPDATE_ACCOUNT_PROFILE_FAILED,
  UPDATE_AVATAR_START,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAILED,
  UPDATE_PHONE_NUMBER_START,
  UPDATE_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_NUMBER_FAILED,
  GET_PLAYER_INFO_START,
  GET_PLAYER_INFO_SUCCESS,
  GET_PLAYER_INFO_FAILED,
  SEND_VERIFY_EMAIL_START,
  SEND_VERIFY_EMAIL_SUCCESS,
  SEND_VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  GET_ALL_ADDRESS_USER_START,
  GET_ALL_ADDRESS_USER_SUCCESS,
  GET_ALL_ADDRESS_USER_FAILED,
} from '../actions/accountProfile'

const initialState = {
  isLoading: false,
  isLoadingUpdatePhone: false,
  playerInfo: {},
  isLoadingPlayerinfo: false,
  allAccountProfile: {},
  isLoadingSendVerify: false,
  isLoadingVerify: false,
  allAddressUser: [],
}

/**
 * Account Profile reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function accountProfileManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_PROFILE_START:
    case UPDATE_ACCOUNT_PROFILE_START:
    case GET_ALL_ADDRESS_USER_START:
    case UPDATE_AVATAR_START:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_PHONE_NUMBER_START:
      return {
        ...state,
        isLoadingUpdatePhone: true,
      }
    case GET_PLAYER_INFO_START:
      return {
        ...state,
        isLoadingPlayerinfo: true,
      }
    case SEND_VERIFY_EMAIL_START:
      return {
        ...state,
        isLoadingUpdatePhone: true,
      }
    case VERIFY_EMAIL_START:
      return {
        ...state,
        isLoadingVerify: true,
      }
    case GET_ACCOUNT_PROFILE_SUCCESS:
      return {
        ...state,
        allAccountProfile: action?.payload?.player_info,
        isLoading: false,
      }
    case UPDATE_ACCOUNT_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isLoadingUpdatePhone: false,
      }
    case GET_PLAYER_INFO_SUCCESS:
      return {
        ...state,
        playerInfo: action?.payload?.player_info,
        isLoadingPlayerinfo: false,
      }
    case SEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoadingUpdatePhone: false,
      }
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoadingVerify: false,
      }
    case GET_ALL_ADDRESS_USER_SUCCESS:
      return {
        ...state,
        allAddressUser: action?.payload?.data,
        isLoading: false,
      }
    case GET_ACCOUNT_PROFILE_FAILED:
    case UPDATE_ACCOUNT_PROFILE_FAILED:
    case GET_ALL_ADDRESS_USER_FAILED:
    case UPDATE_AVATAR_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_PHONE_NUMBER_FAILED:
      return {
        ...state,
        isLoadingUpdatePhone: false,
      }
    case GET_PLAYER_INFO_FAILED:
      return {
        ...state,
        isLoadingPlayerinfo: false,
      }
    case SEND_VERIFY_EMAIL_FAILED:
      return {
        ...state,
        isLoadingUpdatePhone: false,
      }
    case VERIFY_EMAIL_FAILED:
      return {
        ...state,
        isLoadingVerify: false,
      }

    default:
      return state
  }
}
