import { all } from 'redux-saga/effects'

//Auth
import watchLogin from './auth/login'
import watchLogout from './auth/logout'

//Common
import watchGetTema from './common-management/get-tema'
import watchSignBuyShop from './shop-management/signBuyShop'
import watchGetListSaleShop from './shop-management/getListShop'
import watchGetHistories from './common-management/get-histories'

//Farm
import watchGetListFarm from './farm-management/get-list-farm'
import watchGetDetailFarm from './farm-management/get-detail-farm'
import watchGetHistoriesToken from './common-management/get-histories-token'
import watchGetListFarmUser from './farm-management/get-list-farm-user'
import watchGetListMonsterUser from './monster-management/get-list-monster-user'
import watchGetListMemoryMonsterUser from './monster-management/get-list-memoryMonster-user'
import watchGetListGenesisUser from './genesis-management/get-list-genesis-user'
import watchGetListGeneralUser from './general-management/get-list-general-user'

//Item
import watchGetListItemUser from './item-management/get-list-item-user'
import watchGetListItem from './item-management/get-list-item'
import watchGetDetailItem from './item-management/get-detail-item'
import watchGetListMonster from './monster-management/get-list-monster'
import watchGetDetailMonster from './monster-management/get-detail-monster'
import watchGetListFarmSaleUser from './farm-management/get-list-farm-sale-user'
import watchGetListGeneralSaleUser from './general-management/get-list-general-sale-user'
import watchGetListGenesisSaleUser from './genesis-management/get-list-genesis-sale-user'
import watchGetListItemSaleUser from './item-management/get-list-item-sale-user'
import watchGetListMonsterSaleUser from './monster-management/get-list-monster-sale-user'
import watchBuyItemShop from './shop-management/buyItemShop'
import watchGetListRegenerationUser from './regeneration-management/get-list-regeneration-user'

//Account Profile
import watchGetAccountProfile from './accountProfile-management/get-account-profile'
import watchUpdateAccountProfile from './accountProfile-management/update-account-profile'
import watchUpdateAvatarAccount from './accountProfile-management/update-avatar-profile'
import watchSellItemShop from './shop-management/sellItemShop'
import watchUpdatePhoneNumber from './accountProfile-management/update-phone-number'
import watchGetPlayerInfo from './accountProfile-management/get-player-info'
import watchSendVerifyEmail from './accountProfile-management/send-verify-email'
import watchVerifyEmail from './accountProfile-management/verify-email'

//Hash
import watchGetListMonsterHash from './monster-management/get-list-monster-hash'
import watchGetMonsterHashDetail from './monster-management/get-detail-monster-hash'
import watchGetListHashUser from './hash-management/get-list-hash-user'
import watchGetAddAddressUser from './accountProfile-management/get-add-address-user'

/**
 * Root saga
 */

export default function* sagas() {
  yield all([
    //Auth
    watchLogin(),
    watchLogout(),

    //Common
    watchGetTema(),
    watchSignBuyShop(),
    watchGetListSaleShop(),

    //Farm
    watchGetListFarm(),
    watchGetDetailFarm(),
    watchGetHistories(),
    watchGetHistoriesToken(),
    watchGetListFarmUser(),
    watchGetListMonsterUser(),
    watchGetListMemoryMonsterUser(),
    watchGetListItemUser(),
    watchGetListGenesisUser(),
    watchGetListGeneralUser(),

    //Item
    watchGetListItem(),
    watchGetDetailItem(),

    //Monster
    watchGetListMonster(),
    watchGetDetailMonster(),

    // Creted sales
    watchGetListFarmSaleUser(),
    watchGetListGeneralSaleUser(),
    watchGetListGenesisSaleUser(),
    watchGetListItemSaleUser(),
    watchGetListMonsterSaleUser(),
    watchBuyItemShop(),
    watchGetListRegenerationUser(),

    // Account Profile
    watchGetAccountProfile(),
    watchUpdateAccountProfile(),
    watchUpdateAvatarAccount(),
    watchSellItemShop(),
    watchUpdatePhoneNumber(),
    watchGetPlayerInfo(),
    watchSendVerifyEmail(),
    watchVerifyEmail(),
    watchGetAddAddressUser(),

    //Hash
    watchGetListMonsterHash(),
    watchGetMonsterHashDetail(),
    watchGetListHashUser(),
  ])
}
