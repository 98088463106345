import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Pagination,
  Row,
  Spin,
} from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import {
  FARM_SORT_MKP,
  FARM_SORT_MKP_JA,
  SCREEN_SIZE,
} from '~/common/constants'
import {
  ArrowUpOutlined,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { FarmsCard } from '~/components/shared-components/Card/FarmsCard'
import { FilterFarm } from './components/FilterFarm'
import { useFarm } from '~/views/redux/hooks/useFarm'
import { useQueryState } from '~/common/hooks/useQueryState'
import { NoData } from '~/components/shared-components/NoData'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useAccount } from '~/common/hooks/useAccount'
import configs from '~/configs'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  checkTransactionConfirmation,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import SelectWallet from '~/components/Modals/SelectWallet'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'

export const Farm = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  const { user } = useAccount()
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [nftActive, setNftActive] = useState(0)
  const [txHash, setTxHash] = useState()
  // eslint-disable-next-line
  const { keyword, setKeyword } = useQueryState()
  const {
    actions: actionsTema,
    data: { allTEMAs },
  } = useCommon()
  const [sort, setSort] = useState(
    JSON.parse(localStorage.getItem('farmsMarketplace'))?.sort ||
      FARM_SORT_MKP[0],
  )
  const [typeSort, setTypeSort] = useState(
    JSON.parse(localStorage.getItem('farmsMarketplace'))?.typeSort || '-1',
  )
  const [form] = Form.useForm()
  const [terrainCompatibility, setTerrainCompatibility] = useState(
    JSON.parse(localStorage.getItem('farmsMarketplace'))
      ?.terrainCompatibility || [],
  )
  const [boostParameter, setBoostParameter] = useState(() => {
    const storedData = localStorage.getItem('farmsMarketplace')
    return storedData
      ? JSON.parse(storedData)?.boostParameter
      : {
          health: [],
          strength: [],
          intelligent: [],
          dexterity: [],
          agility: [],
          vitality: [],
          fatigue: [],
          stress: [],
          rest: [],
        }
  })
  const [boostPotensial, setBoostPotensial] = useState(() => {
    const storedData = localStorage.getItem('farmsMarketplace')
    return storedData
      ? JSON.parse(storedData)?.boostPotensial
      : {
          health: [],
          strength: [],
          intelligent: [],
          dexterity: [],
          agility: [],
          vitality: [],
          fatigue: [],
          stress: [],
          rest: [],
        }
  })

  const [specialTrainning, setSpecialTrainning] = useState(() => {
    const storedData = localStorage.getItem('farmsMarketplace')
    return storedData
      ? JSON.parse(storedData)?.specialTrainning
      : {
          main_parameter: [],
          sub_parameter: [],
          decrement_parameter: [],
        }
  })

  const [farmTrait, setFarmTrait] = useState(
    JSON.parse(localStorage.getItem('farmsMarketplace'))?.farmTrait || [],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('farmsMarketplace'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('farmsMarketplace'))?.page || 1,
  )
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleChangeSort = (e) => {
    setSort(FARM_SORT_MKP[e.key])
  }

  const {
    actions,
    data: { allFarms, total, isLoading },
  } = useFarm()

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  const handleSubmit = (values) => {
    setKeyword(values?.search ? values?.search?.trim() : '')
  }

  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      search: keyword,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      farm_terrain: terrainCompatibility?.map((data) => data?.value),
      boost_parameter: boostParameter,
      boost_potensial: boostPotensial,
      main_parameter: specialTrainning['main_parameter'],
      sub_parameter: specialTrainning['sub_parameter'],
      decrement_parameter: specialTrainning['decrement_parameter'],
      farm_trait_id: farmTrait,
    }
    actions.getAllFarm(params)
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
    keyword,
    txHash,
  ])

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  useEffect(() => {
    refreshData()
    actionsTema.getTema()
  }, [
    page,
    pageSize,
    keyword,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
    txHash,
  ])

  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            nftActive?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(nftActive?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  nftActive?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(nftActive?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }
  //reponsive Mobile filter
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterFarm, setOpenFilterFarm] = useState(false)
  const showDrawer = () => {
    setOpenFilterFarm(true)
  }

  const onClose = () => {
    setOpenFilterFarm(false)
  }
  const clearAll = () => {
    setTerrainCompatibility([])
    setBoostParameter({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setBoostPotensial({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setSpecialTrainning({
      main_parameter: [],
      sub_parameter: [],
      decrement_parameter: [],
    })
    setFarmTrait([])
  }
  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
  ])
  const pageFilter = JSON.parse(localStorage.getItem('farmsMarketplace'))?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])
  return (
    <BodyCommon>
      <Row className={`${isMobile ? '' : 'container__common mx-auto h-full'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterFarm
                sort={sort}
                typeSort={typeSort}
                page={page}
                pageSize={pageSize}
                terrainCompatibility={terrainCompatibility}
                setTerrainCompatibility={setTerrainCompatibility}
                boostParameter={boostParameter}
                setBoostParameter={setBoostParameter}
                boostPotensial={boostPotensial}
                setBoostPotensial={setBoostPotensial}
                specialTrainning={specialTrainning}
                setSpecialTrainning={setSpecialTrainning}
                farmTrait={farmTrait}
                setFarmTrait={setFarmTrait}
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0 sm:pl-[15px] relative md:sticky top-0 sm:top-[104px] pb-12">
            <div className="pt-0" style={{ marginTop: '100px' }}>
              <div className="flex mt-5 flex-col">
                <Row>
                  <Col xl={16} md={16} sm={24} xs={24}>
                    <Form
                      layout="vertical"
                      onFinish={handleSubmit}
                      form={form}
                      translate="yes"
                      autoComplete="off"
                      size="middle"
                      validateTrigger={['onBlur', 'onChange']}
                    >
                      <Row className="flex justify-between">
                        <Col span={24}>
                          <div
                            className={`${
                              isMobile
                                ? 'text-[24px] ml-[11px] font-normal'
                                : 'text-[24px] font-normal'
                            }`}
                            style={{ color: '#FFFFFF' }}
                          >
                            {t('countFarm', {
                              total: total,
                            })}
                            {total > 1 ? t('more') : ''}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col xl={8} md={8} sm={24} xs={24}>
                    {isMobile ? (
                      <>
                        {' '}
                        <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                          <Dropdown
                            className="flex items-center cursor-pointer ml-3  sort__value"
                            menu={{
                              items:
                                user?.player_language === 'ja'
                                  ? FARM_SORT_MKP_JA
                                  : FARM_SORT_MKP,
                              selectable: true,
                              onClick: handleChangeSort,
                            }}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                              <div className="title">
                                {t(`common.filter.sort.${sort?.valueLanguage}`)}
                              </div>
                            </Button>
                          </Dropdown>

                          <Button
                            className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                            onClick={() => {
                              handleChangeTypeSort()
                            }}
                          >
                            <ArrowUpOutlined
                              style={{ fontSize: 18 }}
                              rotate={typeSort === '1' ? 0 : 180}
                            />
                          </Button>
                          <div>
                            <Button
                              type="primary"
                              onClick={showDrawer}
                              className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                            >
                              <FilterOutlined />
                              {t('filter')}
                            </Button>
                            <Drawer
                              title={t('filter')}
                              width={330}
                              placement="left"
                              onClose={onClose}
                              open={openFilterFarm}
                              extra={
                                <div
                                  className="text-[16px] text-[#FAAD14] cursor-pointer"
                                  onClick={() => {
                                    clearAll()
                                  }}
                                >
                                  {t('clearAll')}
                                </div>
                              }
                            >
                              <div className="flex w-full h-full">
                                <FilterFarm
                                  sort={sort}
                                  page={page}
                                  typeSort={typeSort}
                                  pageSize={pageSize}
                                  terrainCompatibility={terrainCompatibility}
                                  setTerrainCompatibility={
                                    setTerrainCompatibility
                                  }
                                  boostParameter={boostParameter}
                                  setBoostParameter={setBoostParameter}
                                  boostPotensial={boostPotensial}
                                  setBoostPotensial={setBoostPotensial}
                                  specialTrainning={specialTrainning}
                                  setSpecialTrainning={setSpecialTrainning}
                                  farmTrait={farmTrait}
                                  setFarmTrait={setFarmTrait}
                                  classCustom="filter__profile"
                                />
                              </div>
                            </Drawer>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                          <div> {t('sortBy')}</div>

                          <Dropdown
                            className="flex items-center cursor-pointer ml-3  sort__value"
                            menu={{
                              items:
                                user?.player_language === 'ja'
                                  ? FARM_SORT_MKP_JA
                                  : FARM_SORT_MKP,
                              selectable: true,
                              onClick: handleChangeSort,
                            }}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                              <div className="title">
                                {t(`common.filter.sort.${sort?.valueLanguage}`)}
                              </div>
                            </Button>
                          </Dropdown>

                          <Button
                            className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                            onClick={() => {
                              handleChangeTypeSort()
                            }}
                          >
                            <ArrowUpOutlined
                              style={{ fontSize: 18 }}
                              rotate={typeSort === '1' ? 0 : 180}
                            />
                          </Button>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </div>

              {/* Farm */}
              <Spin className="min-h-[350px]" spinning={isLoading}>
                <div className="flex flex-wrap justify-center gap-4 mt-5">
                  {allFarms?.map((data) => (
                    <FarmsCard
                      key={data?._id}
                      data={data}
                      tema={allTEMAs}
                      button={{
                        title:
                          data?.seller?.toLowerCase() ===
                          user?.address_wallet?.toLowerCase()
                            ? 'Cancel'
                            : user?.player_language === 'ja'
                            ? '買う'
                            : 'Buy',
                        functionButton: () => {
                          setNftActive(data)
                          if (
                            data?.seller?.toLowerCase() ===
                            user?.address_wallet?.toLowerCase()
                          ) {
                            showModalCancelSell()
                          } else {
                            showModalCheckout()
                          }
                        },
                      }}
                    />
                  ))}
                </div>
              </Spin>
              {!isLoading && total === 0 && <NoData />}

              {total > 0 && (
                <div className="pagination__common">
                  <Pagination
                    onChange={onChangePage}
                    onShowSizeChange={onChangePageSize}
                    className="mt-5"
                    size="small"
                    locale={{ jump_to: 'Go to', page: '' }}
                    total={total}
                    // defaultCurrent={page}
                    pageSize={pageSize}
                    current={page}
                    showSizeChanger
                    showQuickJumper
                    showTitle
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={nftActive}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
        loadingModal={loadingModal}
        // tema={tema}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </BodyCommon>
  )
}

export default Farm
