import Navbar from './Header/NavbarLeft'
import { ReMonsterTwoBorder } from '../shared-components/Button/ReMonsterTwoBorder'
import { Link } from 'react-router-dom'

import { SCREEN_SIZE } from '~/common/constants'
import SelectWallet from '../Modals/SelectWallet'
import { useEffect, useState } from 'react'
import { formatLongText, formatNumber } from '~/helpers/common'
import { Button, Image } from 'antd'
import { ROUTE } from '~/views/routes/config'
import { useAuth } from '~/views/redux/hooks/useAuth'
import { getBalanceOAS } from '~/blockchain/provider'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'
import { CopyAddressCommon } from '../shared-components/CopyAddressCommon'
import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons'
import { NavMobile } from './NavMobile'

export default function Header(props) {
  const { sdk } = useSDK()
  const { t } = props
  const {
    actions,
    data: { isLoading },
  } = useAuth()
  const history = useHistory()
  const {
    actions: actionTheme,
    data: { balanceOAS, user, mobileNav },
  } = useTheme()

  const { actions: actionsProfile } = useAccountProfile()

  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      actionTheme.updateBalenceOas(res)
      localStorage.setItem('balanceOAS', Number(res).toFixed(3))
    })
  }

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancel = () => {
    setIsModalConnectOpen(false)
  }

  const checkConnect = async () => {
    if (sdk && localStorage.getItem('wallet') === 'MW') {
      if (!(await window.ethereum._metamask.isUnlocked())) {
        actionTheme.updateInfoUser(null)
        sdk?.disconnect()
        sdk?.terminate()
        actions.logout({}, () => {
          history.push('/')
          window.location.reload() // Tải lại trang
        })
      } else {
        sdk
          ?.connect()
          .then(() => {
            sdk.getProvider().on('accountsChanged', (address) => {
              if (
                address[0]?.toLowerCase() !==
                user?.address_wallet?.toLowerCase()
              ) {
                actionTheme.updateInfoUser(null)
                sdk?.disconnect()
                sdk?.terminate()
                actions.logout({}, () => {
                  history.push('/')
                  window.location.reload() // Tải lại trang
                })
              }
            })
          })
          .catch((error) => {
            actionTheme.updateInfoUser(null)
            sdk?.disconnect()
            sdk?.terminate()
            actions.logout({}, () => {
              history.push('/')
              window.location.reload() // Tải lại trang
            })
          })
      }
    }
  }

  useEffect(() => {
    if (user && user !== null) {
      loadBalanceOAS()
      checkConnect()
      actionTheme?.onLocaleChange(user?.player_language || 'en')
    }
  }, [user, sdk])

  useEffect(() => {
    if (user && user !== null) {
      const timer = setTimeout(() => {
        actionsProfile.getAccountProfile(async (res) => {
          if (
            sdk &&
            localStorage.getItem('wallet') === 'MW' &&
            window.ethereum
          ) {
            if (!(await window.ethereum._metamask.isUnlocked())) {
              actionTheme.updateInfoUser(null)
              sdk?.disconnect()
              sdk?.terminate()
              actions.logout({}, () => {
                history.push('/')
              })
            } else {
              sdk
                ?.getProvider()
                .request({
                  method: 'eth_requestAccounts',
                })
                .then(async (accounts) => {
                  if (
                    accounts[0]?.toLowerCase() !==
                    res?.player_info?.address_wallet?.toLowerCase()
                  ) {
                    actionTheme.updateInfoUser(null)
                    sdk?.disconnect()
                    sdk?.terminate()
                    actions.logout({}, () => {
                      history.push('/')
                    })
                  } else {
                    actionTheme.updateInfoUser({
                      ...res?.player_info,
                    })
                  }
                })
            }
          } else {
            actionTheme.updateInfoUser({
              ...res?.player_info,
            })
          }
        })
      }, 10000)
      return () => clearTimeout(timer)
    }
  }, [user])

  const onMobileNavToggle = (state) => {
    actionTheme?.onMobileNavToggle(state)
  }
  return (
    <nav className="main-nav bg-[#00091A]">
      <div className="relative container__common_header  mx-auto !py-3">
        <div className="flex justify-between items-center">
          <Navbar t={t} />
          <div className="flex gap-7 items-center">
            {isMobile && (
              <>
                <div
                  onClick={() => {
                    onMobileNavToggle(!mobileNav)
                  }}
                >
                  {mobileNav ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                </div>

                <NavMobile
                  mobileNav={mobileNav}
                  onMobileNavToggle={onMobileNavToggle}
                />
              </>
            )}

            {!isMobile && user && (
              <>
                <ReMonsterTwoBorder
                  price={formatNumber(user?.bit_balance)}
                  icon={`${process.env.PUBLIC_URL}/svg/coins/bit.svg`}
                />

                <ReMonsterTwoBorder
                  price={formatNumber(balanceOAS)}
                  icon={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                />

                <Link
                  to={ROUTE?.PROFILE_MONSTER_SALES.PATH}
                  className={`h-full w-full`}
                >
                  <div
                    className="flex items-center"
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="flex items-center">
                      <Image
                        className="rounded-full"
                        width={48}
                        height={48}
                        src={`${user?.avatar}?date=${new Date()}`}
                        fallback={`${process.env.PUBLIC_URL}/imgs/common/avatar-default.png`}
                        loading="lazy"
                        preview={false}
                      />
                    </div>
                    <div className="ml-2">
                      <div>{formatLongText(user?.user_name)}</div>
                      <div className="flex gap-1">
                        {/* {formatAddress(user.address_wallet)} */}
                        <CopyAddressCommon>
                          {user.address_wallet}
                        </CopyAddressCommon>
                        {localStorage.getItem('wallet') === 'MW' ? (
                          <img
                            className="w-[25px] h-[25px] object-contain"
                            src={`${process.env.PUBLIC_URL}/imgs/logo/metamask.png`}
                            alt=""
                          />
                        ) : (
                          <img
                            className="w-[20px] h-[20px] object-contain"
                            src={`${process.env.PUBLIC_URL}/imgs/logo/oas-wallet.png`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </>
            )}

            {!user && (
              <Button
                loading={isLoading}
                className="connect__button"
                onClick={showModalConnect}
              >
                Connect Wallet
              </Button>
            )}
          </div>
        </div>
      </div>
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancel,
        }}
        t={t}
      />
    </nav>
  )
}
